import React from "react"

import l from "./layout.module.scss"

export default ({
  extraClass = "",
  color = "#4dcb00",
  fontSize = "16px",
  fontWeight = "normal",
  rotation = "0deg",
  clickHandler = null,
  children,
}) => {
  return (
    <div
      onClick={clickHandler ? clickHandler : null}
      style={{
        display: "flex",
        alignItems: "center",
        color,
        fontSize,
        fontWeight,
        lineHeight: "1em",
        cursor: clickHandler ? "pointer" : "unset",
      }}
      className={[l.arrow_container, extraClass].join(" ")}
    >
      <span
        style={{
          backgroundColor: color,
          color,
          transform: `rotate(${rotation})`,
        }}
        className={l.arrow}
      ></span>
      {children}
    </div>
  )
}
