import React from "react"

import h from "./header.module.scss"

export default ({ active, handleToggle }) => {
  return (
    <div
      // role="button"
      // tabIndex="0"
      onClick={handleToggle}
      className={h.hamburger}
    >
      <span className={h.hamburger.span}></span>
      <span className={h.hamburger.span}></span>
      <span className={h.hamburger.span}></span>
    </div>
  )
}
