import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

// Custom components
import Image from "./image"
import Row from "./row"
import Hamburger from "./hamburger"
import serviceSVG from "./../images/service.svg"

// Imported style modules
import l from "./layout.module.scss"
import h from "./header.module.scss"

const Header = ({ ...data }) => {
  const [active, toggleActive] = useState(false)
  // holds title of currently active menu item that has a submenu
  const [subMenuActive, setSubMenuActive] = useState(false)

  useEffect(() => {
    // on mobile devices render body fixed to allow for scrolling on offcanvas menu
    if (window.innerWidth <= 600) {
      active
        ? (document.body.style.position = "fixed")
        : (document.body.style.position = "unset")
    }
  }, [active])

  const ulRefs = []

  /* Sets state to title of parent menu item */
  const openSubMenu = title => {
    let child = ulRefs[title]
    let parent = child.parentElement
    if (title === subMenuActive) {
      setSubMenuActive("")
      parent.style.height = "0px"
    } else {
      // Close other already opened submenus first
      if (subMenuActive)
        ulRefs[subMenuActive].parentElement.style.height = "0px"
      setSubMenuActive(title)
      parent.style.height = child.clientHeight + "px"
    }
  }

  /* Renders a submenu item config */
  const renderSubMenuItem = item => {
    return (
      <li className={h.sub_menu_item} key={item.url}>
        <Link className={h.link} to={`${item.url}`}>
          {item.title}
        </Link>
      </li>
    )
  }

  // destructure data object
  const { offcanvas_text, site_logo, offer_url: url, link_label } = data.general
  const menuItems = data.menuItems

  return (
    <header>
      <Row extraClasses={[h.header]}>
        <div className={l.vcenter}>
          <div className={h.hamburger_container}>
            <Hamburger
              active={active}
              handleToggle={() => toggleActive(!active)}
            />
          </div>
          <div className={h.logo_container}>
            <Link to="/">
              <Image data={site_logo} />
            </Link>
          </div>
        </div>
      </Row>
      <div className={[h.offcanvas_menu, active ? h.active : ""].join(" ")}>
        <div className={h.upper}>
          <a href={url} className={l.button_arrow}>
            <img src={serviceSVG} />
            {link_label}
          </a>
          <div
            onClick={() => toggleActive(!active)}
            role="button"
            className={h.close_icon}
          ></div>
        </div>
        <div className={h.menu_items}>
          <ul>
            {menuItems.map(i => {
              return (
                <li key={i.title}>
                  {i.child_items ? (
                    <div
                      onClick={() => openSubMenu(i.title)}
                      className={[
                        h.sub_menu,
                        subMenuActive === i.title && h.active,
                      ].join(" ")}
                    >
                      <div className={h.inner}>
                        <span>{i.title}</span>
                        <span className={h.icon_plus}></span>
                      </div>
                      <div className={h.sub_menu_items}>
                        <ul ref={ref => (ulRefs[i.title] = ref)}>
                          {i.child_items.map(subItem =>
                            renderSubMenuItem(subItem)
                          )}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <Link className={h.link} to={`${i.url}`}>
                      {i.title}
                    </Link>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
        <div className={h.lower}>
          <div className={[h.about, l.text_block].join(" ")}>
            {offcanvas_text}
          </div>
          <span>© {new Date().getFullYear()} Limach</span>
        </div>
      </div>
    </header>
  )
}

export default Header
