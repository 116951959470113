import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header.jsx"
import Footer from "./footer"
import "./layout.css"
import l from "./layout.module.scss"

const Layout = ({ children }) => {
  useEffect(() => {
    if (typeof window === "undefined") return

    const scrollTo = e => {
      e.preventDefault()

      const yOffset = -20
      const target = document.querySelector(e.target.getAttribute("href"))
      if (!target) return

      const y =
        target.getBoundingClientRect().top + window.pageYOffset + yOffset
      window.scrollTo({ top: y, behavior: "smooth" })
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", scrollTo)

      return () => {
        anchor.removeEventListener("click", scrollTo)
      }
    })
  }, [])

  const layoutData = useStaticQuery(graphql`
    query siteData {
      allWordpressMenusMenusItems {
        edges {
          node {
            items {
              title
              url
              child_items {
                title
                url
              }
            }
            slug
          }
        }
      }
      wordpressSiteMetadata {
        url
      }
      wordpressPage(slug: { eq: "header-footer" }) {
        acf {
          header {
            offcanvas_text
            offer_url
            link_label
            site_logo {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 280) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            footer_menu_title
            footer_contact_title
            footer_products_title
            contact_cols {
              title
              content
              url
            }
            socials {
              icon {
                alt_text
                localFile {
                  id
                  childImageSharp {
                    fluid(maxWidth: 280) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
              url
            }
          }
        }
      }
    }
  `)

  // Strip menu items slugs from wp-backend url
  const wpSiteUrl = layoutData.wordpressSiteMetadata.url
  const menus = layoutData.allWordpressMenusMenusItems.edges.map(
    ({ node: menu }) => {
      const menuWithUpdatedSlugs = menu.items.map(i => {
        let obj = { ...i }
        obj.url = obj.url.replace(wpSiteUrl, "")

        // Check if item contains child items
        if (obj.child_items) {
          obj.child_items = obj.child_items.map(c => {
            let child_obj = { ...c }
            child_obj.url = child_obj.url.replace(wpSiteUrl, "")
            return child_obj
          })
        }

        return obj
      })
      return { items: menuWithUpdatedSlugs, slug: menu.slug }
    }
  )

  // General data
  const general = layoutData.wordpressPage.acf.header

  // Filter out menu for header and footer
  const headerMenuItems = menus.filter(m => m.slug === "main-menu")[0].items
  const footerMenus = menus.filter(
    m => ["footermenu-links", "footermenu-rechts"].indexOf(m.slug) > -1
  )

  return (
    <>
      <Header menuItems={headerMenuItems} general={general} />
      <main className={l.main}>{children}</main>
      <Footer menus={footerMenus} general={general} />
    </>
  )
}

export default Layout
