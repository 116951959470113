import React from "react"
import { Link } from "gatsby"

import Image from "./image"
import Row from "./row"

import l from "./layout.module.scss"
import f from "./footer.module.scss"

const Footer = ({ ...data }) => {
  // destructure data object
  const hfData = data.general
  // Containing left and right menu
  const menus = data.menus

  const leftMenuItems = menus.find(m => m.slug.includes("links"))
  const rightMenuItems = menus.find(m => m.slug.includes("rechts"))

  return (
    <footer className={f.footer}>
      <Row>
        <div className={[l.col1, l.section_grid_3, f.inner].join(" ")}>
          <div>
            <Link to="/">
              <Image
                style={{ marginBottom: "2rem", maxWidth: "280px" }}
                data={hfData.site_logo}
              />
            </Link>
            <p className={l.text_block}>{hfData.offcanvas_text}</p>
            <div className={f.socials}>
              {hfData.socials.map((i, idx) => (
                <a key={i.icon.localFile?.id ?? idx} href={i.url}>
                  <Image data={i.icon} />
                </a>
              ))}
            </div>
          </div>
          <div className={f.section_grid_05}>
            <div>
              <h5>{hfData.footer_menu_title}</h5>
              <ul>
                {leftMenuItems.items.map(i => (
                  <li key={i.title}>
                    <Link to={`${i.url}`}>{i.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h5>{hfData.footer_products_title}</h5>
              <ul>
                {rightMenuItems.items.map(i => (
                  <li key={i.url}>
                    <Link to={`${i.url}`}>{i.title}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className={f.contact_options}>
            <h5>{hfData.footer_contact_title}</h5>
            <div className={f.section_grid_05}>
              {hfData.contact_cols.map((col, index) => (
                <ul key={index}>
                  <li>
                    <strong>{col.title}</strong>
                  </li>
                  <li>
                    {col.url ? (
                      <a href={col.url}>{col.content}</a>
                    ) : (
                      col.content
                    )}
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
        <div className={[l.col1, f.copyright_bar].join(" ")}>
          <span>
            © {new Date().getFullYear()} Limach Electric Construction Machines
          </span>
          <span>
            Website:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://organisatieservice.nl"
            >
              Organisatieservice
            </a>
          </span>
        </div>
      </Row>
    </footer>
  )
}

export default Footer
