import React from "react"

const Spacer = ({
  height = "4rem",
  alignItems = "center",
  justifyContent = "inherit",
  children,
}) => (
  <div
    style={{
      height,
      width: "100%",
      backgroundColor: "transparent",
      display: "flex",
      flexDirection: "row",
      alignItems,
      justifyContent,
    }}
  >
    {children}
  </div>
)

export default Spacer
