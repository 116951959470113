import React from "react"

import Image from "./image"

import l from "./layout.module.scss"

/* Row component main class only contains left-right padding + has flexbox enabled*/
export default ({
  backgroundImage,
  backgroundColor = "transparant",
  extraClasses = [""],
  id = null,
  children,
}) => {
  return (
    <div
      className={[l.row, ...extraClasses].join(" ")}
      style={!backgroundImage ? { backgroundColor } : null}
      id={id}
    >
      {backgroundImage ? (
        <Image
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: -1,
          }}
          data={backgroundImage}
        />
      ) : null}

      <div className={l.container}>{children}</div>
    </div>
  )
}
